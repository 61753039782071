import React, { Fragment } from 'react';
import { FdpqWidgetWrapperArgo } from '@packages/fdpq-widget';
import { config, theme, floodlightTagging } from '@packages/fdpq-core/config/kbb-argo';
import PageHead from '../components/PageHead';
import Global from '../components/Global';
// Emotion supports different styling options, all of which are supported by gatsby-plugin-emotion out of the box.

const IndexPage = () => (
  <Fragment>
    <PageHead />
    <Global />
    <FdpqWidgetWrapperArgo initialData={{ theme, config, floodlightTagging }} />
  </Fragment>
);

export default IndexPage;
