import commonConfig from '../common';

const appConfig = {
  appType: 'KBB',
  app: {
    config: {
      listingTypeConfig: {
        vehicleListingType: 'new',
      },
    },
  },
  ...commonConfig,
}; 

export default appConfig;
