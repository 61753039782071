import { FLOODLIGHT_KEYS } from '../../constants/app';

const hondaFloodlight = {
  id: 'DC-4114413',
  send_to_dealers: 'DC-4114413/coxau0/honda00f+standard',
  send_to_contacts: 'DC-4114413/coxau0/honda00f+standard',
  send_to_thanks: 'DC-4114413/coxau0/honda00g+standard',
  template: {
    allow_custom_scripts: true,
    u1: FLOODLIGHT_KEYS.MODEL,
    u2: FLOODLIGHT_KEYS.YEAR,
  },
};

const acuraFloodlight = {
  id: 'DC-4114412',
  send_to_dealers: 'DC-4114412/coxau0/acura00f+standard',
  send_to_contacts: 'DC-4114412/coxau0/acura00f+standard',
  send_to_thanks: 'DC-4114412/coxau0/acura00g+standard',
  template: {
    allow_custom_scripts: true,
    u1: FLOODLIGHT_KEYS.MODEL,
    u2: FLOODLIGHT_KEYS.YEAR,
  },
};

const hyundaiFloodlight = {
  id: 'DC-4236604',
  send_to_dealers: 'DC-4236604/endemics/hmale0+standard',
  send_to_contacts: 'DC-4236604/endemics/hmale0+standard',
  send_to_thanks: 'DC-4236604/endemics/hmahv0+standard',
  template: {
    allow_custom_scripts: true,
    u1: FLOODLIGHT_KEYS.MODEL,
    u2: FLOODLIGHT_KEYS.YEAR,
  },
};

const gmcFloodlight = {
  id: 'DC-3924647',
  send_to_dealers: 'DC-3924647/gmckbb/usgmc00i+standard',
  send_to_contacts: 'DC-3924647/gmckbb/usgmc00i+standard',
  send_to_thanks: 'DC-3924647/gmckbb/usgmc00h+standard',
  template: {
    allow_custom_scripts: true,
    u19: 'ONR',
    u20: 'RAQ',
    u3: FLOODLIGHT_KEYS.YEAR,
    u4: FLOODLIGHT_KEYS.MODEL,
    u5: FLOODLIGHT_KEYS.TRIM,
  },
};

export const floodlightTagging = {
  honda: hondaFloodlight,
  acura: acuraFloodlight,
  hyundai: hyundaiFloodlight,
  gmc: gmcFloodlight,
};

export default floodlightTagging;
