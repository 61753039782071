import Size from '@argo/principles/constants/size';
import {
  colors,
  fonts,
  links,
  text,
  pageContent,
} from '../vars';

const theme = {
  global: {
    fontFamily: fonts.kbb.fontFamily,
    pageContent: {
      backgroundColor: colors.kbb.gray3,
      borderRadius: pageContent.global.borderRadius,
    },
  },
  header: {
    backgroundColor: 'transparent',
    color: colors.global.black1,
    fontSize: `${Size.md}px`,
    textTransform: 'initial',
  },
  keyline: {
    borderColor: colors.kbb.gray1,
  },
  dropdown: {
    errorBorderColor: colors.global.red1,
    errorBorderRadius: '5px',
  },
  text: {
    color: colors.global.blue4,
    subtitle: colors.global.blue2,
    errorColor: colors.global.red1,
  },
  box: {
    backgroundColorDisabled: colors.kbb.gray2,
    border: `1px solid ${colors.global.gray1}`,
    borderRadius: '3px',
    baseGlowingColor: colors.kbb.blue1,
    errorGlowingColor: colors.global.red1,
    height: `${Size.xl}px`,
    lineHeight: '20px',
  },
  description: {
    title: {
      fontSize: `${Size.sm}px`,
      color: colors.global.black1,
    },
    subtitle: {
      color: colors.global.black1,
    },
  },
  changeZipButton: {
    color: colors.kbb.blue1,
  },
  vehicleInfo: {
    vehicleModel: {
      color: colors.global.black1,
    },
    vehicleTrim: {
      color: colors.global.black1,
    },
    changeVehicleLink: {
      color: colors.kbb.blue1,
    },
  },
  keylineShadow: 'none',
  // step 1
  // step 2
  dealerSelectorView: {
    dealerBlock: {
      checkboxBackgroundColor: colors.kbb.blue2,
      dealershipInfo: {
        color: colors.global.black1,
        distanceDiv: {
          color: colors.global.gray4,
        },
      },
    },
  },
  // step 3
  contactFormView: {
    disclaimer: {
      disclaimerText: text.kbb.contactFormView.disclaimer.disclaimerText,
      linkText: text.kbb.contactFormView.disclaimer.linkText,
      linkTextDecoration: 'none',
      disclaimerLinkUrl: links.kbb.disclaimerLinkUrl,
    },
  },
  // step 4
  thankYouPage: {
    checkMarkColor: colors.global.green1,
  },
};

export default theme;
